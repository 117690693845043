import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import IntroHostingPlansParamsReseller from "../components/HostingPlans/IntroHostingPlansParamsReseller"
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const GazduireWebSsd = () => {
    return (
        <Layout>
            <SEO title="Alege să fii partener reseller hosting la Sitebunker.ro - Găzduire reseller"
                 description="Ca reseller hosting la Sitebunker te bucuri de multiple beneficii și securitate sporită. Descoperă acum pachetele de găzduire reseller care ți se potrivesc."
            />
            <Navbar/>

            <IntroHostingBgWhite
                title={'Devino Reseller la Sitebunker și ai resurse dedicate pentru clienții tăi'}
                subtitle={'Vezi care este pachetul potrivit pentru tine și bucură-te de beneficiul gestionării resurselor alocate conturilor tale de cPanel prin CloudLinux LVE Manager.'}
            />

            <HostingPlanBlock category="Reseller"/>
            <BannerArea
                pageTitle="Reseller Hosting"
                pageSubtitle="Devino reseller hosting SiteBunker şi beneficiazã de avantajele unui parteneriat de succes."
                pageDescription="Resurse premium, cPanel/WHM intuitiv, viteză de încărcare la superlativ, stabilitate şi securitate, toate asigurate la costuri predictibile. Înscrie-te acum la SiteBunker ca reseller hosting şi testează beneficiile."
                svgIllustration="reseller"
            />
            <PromoInfoRowParams
                title1={"Găzduire premium pentru reseller hosting"}
                title2={"Administrare şi alocare facilă a resurselor "}
                title3={"Migrare gratuită a contului de reseller hosting"}
                paragraph1={"Fiecare reseller hosting beneficiază de avantajele unui parteneriat de încredere. Pentru că succesul tău reprezintă şi succesul SiteBunker, îți punem la dispoziție o gamă largă de servicii complete de hosting cu resurse premium incluse."}
                paragraph2={"Creezi uşor pachetele de cPanel şi aloci rapid resursele dorite pentru fiecare pachet în parte. Ai 100% control asupra resurselor totale alocate contului tău de hosting reseller şi distribui spațiul de stocare, CPU, RAM, EP, NPROC, PMEM, IO şi IOPS după necesități."}
                paragraph3={"La SiteBunker îți mutăm contul de Reseller Hosting gratuit şi rapid. Tot ce trebuie să faci este să ne oferi datele de acces WHM furnizate de vechiul provider de hosting, iar echipa suport se va ocupa în totalitate de migrare."}/>
            <InfoBlocks/>
            <ClientFeedback
                title={"Motivele pentru care clienții au ales serviciile de "}
                bold={"găzduire SiteBunker!"}/>
            <SiteBenefitsParams
                mainTitle={"HOSTING RESELLER CU BENEFICII ŞI RESURSE PREMIUM INCLUSE"}
                title1="BACKUP ZILNIC AUTOMAT"
                title2="SECURITATE PERFORMANTĂ"
                title3="SUPORT 24/7"
                text1="Pentru siguranța tuturor datelor stocate în contul tău de reseller hosting efectuăm automat şi gratuit backup zilnic într-o locație externă."
                text2="La SiteBunker ai garantate protecția avansată anti-hacking Imunify360 şi securitatea sporită pentru atacurile de tip DDoS, asigurată de noua protecție Arbor implementată."
                text3="Beneficiezi de asistență tehnică 24/7 la SiteBunker. Contactează echipa suport în doar câteva clickuri şi îți răspundem prompt la ticket."/>
            <CustomerSupportParams
                title="Disponibil la SiteBunker - Servere VPS România"
                paragraph1="La SiteBunker ai la dispoziție servere VPS propulsate de procesoare AMD EPYC cu stocare full-SSD NVMe în RAID 10. "
                paragraph2=""
                buttonText="Cere detalii aici"
                differentLink="/servere-vps"/>
            {/*<Parteneri/>*/}
            <Footer/>
        </Layout>
    );
}

export default GazduireWebSsd;
