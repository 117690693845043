import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"

const SiteBenefitsParams = ({mainTitle, title1,title2,title3,text1,text2,text3}) => {
  return (
    <section id="site-benefits">
      <div className="container">
        <div className="title-row">
          <h2 className="h3-title text-center mb-4">{mainTitle}</h2>
        </div>
        <div className="row">
          <div className="col-md-4 benefit-col">
            <img src={servIcon} loading="lazy"  alt="" />
            <h3 className="benefits-title">{title1}</h3>
            <p>{text1}</p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={notifIcon} loading="lazy" alt="" />
            <h3 className="benefits-title">{title2}</h3>
            <p>{text2}</p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={antivirusIcon} loading="lazy" alt="" />
            <h3 className="benefits-title">{title3}</h3>
            <p>{text3}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SiteBenefitsParams
