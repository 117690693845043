import React from "react"
import ReactTooltip from "react-tooltip"
import { Link } from "gatsby"
import aboutUs from "../../assets/images/new-imgs/about-us-2.png"


const PromoInfoRowParams = ({title1,title2,title3,paragraph1,paragraph2,paragraph3, buttonHere}) => {
  const text3 = {paragraph3}
  return (
  <section>
    <section id="promo-info-row">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 info-text">
            <h2 className="h3-title mb-3">{title1}</h2>
            <p>{paragraph1}</p>

            <h2 className="h3-title mb-3 mt-5">{title2}</h2>
            <p>{paragraph2} <Link to="/servere-vps/">{buttonHere}</Link></p>

          </div>
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={aboutUs} loading="lazy" alt="Gazduire Web" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="packages-intro" className={`${text3.paragraph3 ? "d-block" : "d-none"}`}>
      <div className="packages-area pb-2 hosting-plans-intro container-fluid pt-100">
        <div className="container">
          <div className="section-title section-title-800">
            <h3 className="h3-title align-items-center text-white text-center">{title3}
              <i className="ml-1 bx bxs-message-rounded-error heartbeat"
                 data-for="info-message"
                 data-place="top"
                 data-tip="Daca vechiul dvs provider de hosting foloseste cPanel. *Migrarile se fac in intervalul Luni - Vineri 9-23"
                 data-effect="solid"
              />
            </h3>
            <ReactTooltip id="info-message" />
            <p className="text-white mb-50">{paragraph3}</p>
          </div>
        </div>
      </div>
    </section>
  </section>

  )
}

export default PromoInfoRowParams
